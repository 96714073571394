import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Me from '../components/me';

const AboutPage = () => (
    <Layout pageNameClass={'about'}>
        <SEO title='About' description={'Piotr Sienkiewicz - developer with over 15 years professional experience'}/>
        <h2>Hello, my name is Piotr Sienkiewicz</h2>
        <div className={'about-text'}>
            <div>
                <Me/>
            </div>
            <div style={{padding: '2rem'}}>
                <p>
                    I am a developer with over 15 years professional experience.
                </p>
                <p>
                    <b>Data & Machine Learning Expert</b> @ <a href="https://www.obido.pl">obido.pl</a>
                </p>
                <p>
                    My interests include web development, electronics, 3D, computer vision, and photography.
                </p>
                <p>
                    Contact: sienkiewicz@gmail.com
                </p>
            </div>
        </div>
    </Layout>
)

export default AboutPage
